import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  data () {
    return {
      groupList: [],
      saveBtnHover: false,
      savePanelHover: false,
      showSavePanel: false,

      showSaveResultWin: false,
      duration: 1500,
      tipType: 'success',
      saveResultText: '收藏成功，请到在线文献管理查看！'
    }
  },
  mounted () {
    this.loadGroupList()
  },
  methods: {
    loadGroupList () {
      this.$http.get(`${this.httpRoot}/group/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.groupList = res.groupList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleSaveMouseEnter () {
      this.saveBtnHover = true
      this.showSavePanel = true
    },
    handleSaveMouseLeave () {
      this.saveBtnHover = false
      setTimeout(() => {
        if (!this.savePanelHover && !this.saveBtnHover) {
          this.showSavePanel = false
        }
      }, 500)
    },
    handleSavePanelMouseEnter () {
      this.savePanelHover = true
    },
    handleSavePanelMouseLeave () {
      this.savePanelHover = false
      this.handleSaveMouseLeave()
    },
    handleGroupAdd (group) {
      this.showSavePanel = false
      this.$http.post(`${this.httpRoot}/group/paper/add`, {id: group.id, pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.tipType = 'success'
          this.duration = 1500
          this.saveResultText = '收藏成功，请到在线文献管理查看！'
          this.showSaveResultWin = true
        } else {
          // updateStorageItem('route', {name: 'paperSearch', query: {'term': this.term}})
          this.showLoginTipWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
