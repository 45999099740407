import { authenticate, catchExpired } from '../../../utils/auth'
export default {
  methods: {
    loadPaperRest (callback) {
      this.$http.get(`${this.httpRoot}/paper/rest`, authenticate({params: {pmid: this.paper.id}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.paper) {
          this.paper.pmcCites = res.paper.pmcCites || []
          this.paper.citedby = res.paper.citedby || 0
          this.paper.alt = res.paper.alt || 0
          this.paper.altId = res.paper.altId || ''
          this.paper.score = res.paper.score || 0
          this.paper.tCh = res.paper.tCh || ''
          this.paper.abCh = res.paper.abCh || ''
          // this.paper.status = '/paper/list/mobile已经取得'
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadPaperOne (pmid, callback) {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/paper/list`, {pmids: [pmid]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.paperList && res.paperList.length) {
          this.paper = res.paperList[0]
          this.paper = this.paperCopy()
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    // loadPaperPmcCites (callback) {
    //   if (!this.paper.pmcCites || !this.paper.pmcCites.length) {

    //     this.$http.post(`${this.httpRoot}/paper/cite/pmc`, {pmids: [this.paper.id]}, authenticate())
    //     .then(res => res.json())
    //     .then(res => {
    //       if (res.status === 'success') {
    //         if (typeof callback === 'function') {
    //           callback(res.pmcCites || {})
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       catchExpired(err, this)
    //     })
    //   }
    // },
    loadAltmetric () {
      if (this.paper.alt === 0) {
        if (this.paper && this.paper.ids.doi) {
          this.$http.get(`https://api.altmetric.com/v1/doi/${this.paper.ids.doi}`, authenticate())
          .then(res => res.json())
          .then(res => {
            if (res.score) {
              this.paper.alt = res.score
              this.paper.altId = res.altmetric_id
              this.getScore(res.score, String(res.altmetric_id), this.paper.id)
            }
          })
          .catch(err => {
            // 有可能altmetric 阻塞
            if (err.status === 404 && err.body === 'Not Found') {
              this.getScore(-1, '', this.paper.id)
            }
            catchExpired(err, this)
          })
        } else {
          // 没有doi, 设置 altmetric 为 -1
          this.getScore(-1, '', this.paper.id)
        }
      } else if (this.paper.score <= 0) {
        this.getScore(this.paper.alt, this.paper.altId, this.paper.id)
      }
    },
    getScore (altmetric, altmetricId, pmid) {
      this.$http.post(`${this.httpRoot}/paper/score`, {altmetric, altmetricId, pmid}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.score) {
          this.paper.score = res.score
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    paperCopy () {
      let p = this.paper
      p.authorstr = this.authorstr()
      p.authorIdx = this.authorIdx()
      p.authorafs = this.authorafs()
      p.volumeIssue = this.volumeIssue()
      p.publicationDate = this.publicationDate()
      p.journalTitle = this.journalTitle()
      p.abstract = this.abstract()
      p.abstractHTML = this.abstractHTML()
      p.pubmedLink = this.pubmedLink()
      p.doiLink = this.doiLink()
      p.journalFullLink = this.journalFullLink()
      p.pmcFullLink = this.pmcFullLink()
      p.sciHubLink = this.sciHubLink()
      p.sciHubLinkPre = this.sciHubLinkPre()
      p.sciHubLinkPost = this.sciHubLinkPost()
      this.resetTitle(p)
      return p
    },
    // =====================================================================================//
    authorstr () {
      let authorstring = ''
      if (this.paper && this.paper.aus) {
        this.paper.aus.forEach((author, index) => {
          let a = author.ln + ' ' + author.init
          if (index > 0) {
            authorstring += ', ' + a
          } else {
            authorstring += a
          }
        })
      }
      return authorstring
    },
    authorIdx () {
      let authidx = []
      if (this.paper && this.paper.aus) {
        this.paper.aus.forEach((author, index) => {
          let a = author.fn + ' ' + author.ln
          if (index <= 1) {
            authidx.push({name: a, idx: index})
          } else if (index === this.paper.aus.length - 2) {
            authidx.push({name: a, idx: -2})
          } else if (index === this.paper.aus.length - 1) {
            authidx.push({name: a, idx: -1})
          }
        })
      }
      return authidx
    },
    authorafs () {
      let authorAfsList = []
      let affilationList = []
      if (this.paper && this.paper.aus) {
        this.paper.aus.forEach(author => {
          let item = {name: '', afsIdxList: []}
          item.name = author.fn + ' ' + author.ln
          let afs = author.affs || []
          afs.forEach(a => {
            let found = false
            affilationList.forEach((b, index) => {
              if (a === b) {
                found = true
                item.afsIdxList.push(index + 1)
              }
            })
            if (!found) {
              affilationList.push(a)
              item.afsIdxList.push(affilationList.length)
            }
          })
          authorAfsList.push(item)
        })
      }
      return { authorAfsList, affilationList }
    },
    volumeIssue () {
      let vi = ''
      if (this.paper.j) {
        if (this.paper.j.vol) vi = this.paper.j.vol
        if (this.paper.j.issue) vi = vi ? vi + ' (' + this.paper.j.issue + ')' : '(' + this.paper.j.issue + ')'
      }
      if (this.paper.a.pgn) {
        vi = vi ? vi + ', ' + this.paper.a.pgn : this.paper.a.pgn
      }
      return vi
    },
    publicationDate () {
      let date = ''
      if (this.paper.j && this.paper.j.date) date = this.paper.j.date
      if (this.paper.b && this.paper.b.date && this.paper.b.date.y) date = this.paper.b.date // date是个固定死对象
      if (!date && this.paper.a.date && this.paper.a.date.y) date = this.paper.a.date // date是个固定死对象
      if (typeof date === 'object') {
        date = (date.y || '') + ' ' + (date.m || '') + ' ' + (date.d || '')
      }
      return date
    },
    journalTitle () {
      let jtitle = ''
      if (this.paper.j && this.paper.j.abbr) jtitle = this.paper.j.abbr
      if (this.paper.b && this.paper.b.pubName) jtitle = this.paper.b.pubName  // 出版社名称
      if (this.paper.j && this.paper.j.t && !jtitle) jtitle = this.paper.j.t
      let parts = jtitle.split('.')
      jtitle = parts.join('')
      jtitle = jtitle + '.'
      return jtitle
    },
    abstract () {
      let text = ''
      if (this.paper.a.abs) {
        this.paper.a.abs.forEach(abs => {
          text = abs.l ? text + abs.l + ': ' + abs.v : text + abs.v
        })
      }
      if (!text && this.paper.b.ab) {
        text = this.paper.b.ab
      }
      return text
    },
    abstractHTML () {
      let text = ''
      if (this.paper.a.abs) {
        this.paper.a.abs.forEach(abs => {
          text = abs.l ? text + '<p><b>' + abs.l + ':</b> ' + abs.v + '</p>' : text + abs.v
        })
      }
      if (!text && this.paper.b.ab) {
        text = this.paper.b.ab
      }
      return text
    },
    // 将book.title切换到article.title上
    resetTitle (p) {
      if (!p.a.t && this.paper.b.t) {
        p.a.t = this.paper.b.t
      }
    },
    pubmedLink () {
      return 'https://www.ncbi.nlm.nih.gov/pubmed/' + this.paper.id
    },
    doiLink () {
      let link = ''
      if (this.paper.ids && this.paper.ids.doi) {
        link = 'https://doi.org/' + this.paper.ids.doi
      }
      return link
    },
    journalFullLink () {
      let link = ''
      if (this.paper.ids && this.paper.ids.doi) {
        link = 'http://dx.doi.org/' + this.paper.ids.doi
      }
      return link
    },
    pmcFullLink () {
      let pmclink = ''
      if (this.paper.ids && this.paper.ids.pmcid) {
        pmclink = 'https://www.ncbi.nlm.nih.gov/pmc/articles/' + this.paper.ids.pmcid
      }
      return pmclink
    },
    sciHubLink () {
      if (this.paper.ids && this.paper.ids.doi) {
        return 'http://sci-hub.si/https://www.ncbi.nlm.nih.gov/pubmed/' + this.paper.ids.doi
      } else {
        return 'http://sci-hub.si/https://www.ncbi.nlm.nih.gov/pubmed/' + this.paper.id
      }
    },
    sciHubLinkPre () {
      return 'https://'
    },
    sciHubLinkPost () {
      if (this.paper.ids && this.paper.ids.doi) {
        return '/' + this.paper.ids.doi
      } else {
        return '/' + this.paper.id
      }
    }
  }
}
