<template>
  <modal v-model="showModal" dimmer maskClose title="">
    <div class="big-fig-win">
      <div class="fig-panel">
        <img :src="fig.imgUrl" alt="">
      </div>
      <div class="fig-footer">
        <div v-html="fig.caption"></div>
      </div>
      <i class="arrow-left fa fa-angle-left" v-if="index>0" @click="goPrev"></i>
      <i class="arrow-right fa fa-angle-right" v-if="index<figSum-1" @click="goNext"></i>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fig: {
      type: Object,
      default: function () { return {} }
    },
    index: Number,
    figSum: Number
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    goPrev () {
      this.$emit('on-prev', this.index - 1)
    },
    goNext () {
      this.$emit('on-next', this.index + 1)
    }
  }
}
</script>
<style lang="less">
@media screen and (max-width: 1700px) {
  .big-fig-win {
    max-height: 800px;
    width: 1240px;
    overflow-y: auto;
    position: relative;
  }
}
@media screen and (max-width: 1533px) {
  .big-fig-win {
    max-height: 700px;
    width: 1240px;
    overflow-y: auto;
    position: relative;
  }
}
@media screen and (max-width: 1400px) {
  .big-fig-win {
    max-height: 580px;
    width: 1000px;
    overflow-y: auto;
    position: relative;
  }
}
.fig-panel {
  padding: 10px 15px 0 15px;
  text-align: center;
  // &>img {
  //   max-height: 400px;
  // }
}
.fig-footer {
  padding: 0 15px 15px 15px;
  // max-height: 200px;
}
.arrow-left, .arrow-right {
  position: absolute;
  font-size: 60px;
  top: 45%;
  color: #888;
  cursor: pointer;
}
.arrow-left {
  left: 10px;
}
.arrow-right {
  right: 10px;
}
</style>
