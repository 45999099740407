<template>
  <div>
    <loading-bar v-if="showLoading"></loading-bar>
    <div class="heading">
      <div class="search-box">
        <search-form @on-term="onTerm" @on-search="onSearch"></search-form>
      </div>
      <div class="page-box header-tools-wrap">
        <div class="header-tools">
          <button class="save-results" @click.stop="handleCollectToggle"
            @mouseenter="handleSaveMouseEnter"
            @mouseleave="handleSaveMouseLeave">
            <span v-if="paper.status==='collect'"><span class="fa fa-heart" style="margin-right:0.2rem;"></span>取消收藏</span>
            <span v-else><span class="fa fa-heart-o" style="margin-right:0.2rem;"></span>收藏</span>
          </button>
          <ul class="save-results-panel" v-show="showSavePanel"
            @mouseenter="handleSavePanelMouseEnter"
            @mouseleave="handleSavePanelMouseLeave">
            <li @click="handleCollectToggle">{{paper.status==='collect' ? '取消收藏' : '收藏'}}</li>
            <li @click="handleGroupAdd(item)" v-for="item in groupList" :key="item.id">{{item.name}}</li>
          </ul>
          <button class="read-results" @click.stop="handleReadToggle">
            <span v-if="paper.read"><span class="unread">&nbsp;</span><span>取消已读</span></span>
            <span v-else><span class="read">&nbsp;</span><span>已读</span></span>
          </button>
        </div>
        <div class="header-tools">
          <a class="label" style="padding-right:5px;">全文链接</a>
          <div v-if="paper.journalFullLink">
            <a target="_blank" class="journal-full-link" :href="paper.journalFullLink" style="text-decoration:underline;">
              <img v-if="paper.journalFullImage" :src="paper.journalFullImage" alt="">
              <span v-else>期刊原文链接</span>
            </a>
          </div>
          <div v-if="paper.pmcFullLink"><a target="_blank" class="pmc-full-link" :href="paper.pmcFullLink"></a></div>
          <div v-if="paper.sciHubLink"><a target="_blank" class="sci-hub-link" :href="paper.sciHubLinkPre+scihubDomain+paper.sciHubLinkPost" @click="onLinkClick">&nbsp;</a></div>
          <div v-if="paper.fulltextLink"><a target="_blank" :class="[paper.fulltextLink.toLowerCase().indexOf('pdf')>0?'pdf-link':'web-link']" :href="paper.fulltextLink" @click="onLinkClick"></a></div>
          <div class="fulltext-help" @click="onFulltextHelp"></div>
        </div>
      </div>
    </div>
    <div class="boding page-box">
      <div class="p-header">
        <div class="p-attr">
          <!-- <span class="type" v-if="paper.pubType">{{paper.pubType}}</span> -->
          <span class="type review" v-if="paper.pubTypes && paper.pubTypes.indexOf('Review')>=0">Review</span>
          <span class="yxy-score-p" v-if="paper.score>0" title="银杏因子">
            <span>{{paper.score.toFixed(1)}}</span>
          </span>
          <span class="quality" :class="[paper.quality>=4?'green':paper.quality>=3?'blue':paper.quality>=2?'yellow':paper.quality>=1?'red':'grey']">{{paper.quality?paper.quality+`区`:'未录'}}</span>
          <span class="if" :class="[paper.impactFactor>=10?'red':paper.impactFactor>=5?'yellow':paper.impactFactor>=3?'blue':paper.impactFactor>0?'green':'grey']">{{paper.impactFactor?`IF:`+paper.impactFactor:'Not Found'}}</span>
          <span class="journal" :class="[paper.impactFactor>=10?'red':paper.impactFactor>=5?'yellow':paper.impactFactor>=3?'blue':paper.impactFactor>0?'green':'grey']" @mouseenter="handleJournalMouseEnter($event)" @mouseleave="handleJournalMouseLeave()">
            <router-link class="journal-title" target="_blank" :to="{name: 'paperSearch', query: {term: paper.journalTitle + '[Journal]'}}">
              {{paper.journalTitle}}
            </router-link>
          </span>
          <span class="date" v-if="paper.publicationDate">{{paper.publicationDate}}<span v-if="paper.volumeIssue">;</span><span v-else>.</span></span>
          <span class="vol" v-if="paper.volumeIssue">{{paper.volumeIssue}}.</span>
          <span class="pii" v-if="!paper.volumeIssue && paper.ids && paper.ids.pii">{{`pii: `+paper.ids.pii}}.</span>
          <span class="doi" v-if="paper.ids && paper.ids.doi">{{`doi: `+paper.ids.doi}}</span>
          <span class="pubst" v-if="paper.pubSt === 'aheadofprint'">Online ahead of print.</span>
        </div>
        <h1 class="p-title">
          {{paper.a && paper.a.t}}
          <a v-if="paper.alt>0" class="altmetric-icon" target="_blank" :href="`https://www.altmetric.com/details/${paper.altId}`"></a>
          <a v-if="paper.alt>0" class="altmetric-num">{{Math.floor(paper.alt * 100) / 100}}</a>
          <template v-if="paper.citedby">
            <span class="cite-num icon fa fa-quote-left" title="被引用">:</span>
            <span class="cite-num" title="被引用">{{paper.citedby}}</span>
          </template>
          <router-link v-if="!paper.citedby && paper.pmcCites && paper.pmcCites.length" target="_blank" :to="{name: 'paperSearch', query: {pmcCites: paper.pmcCites.join(',')}}">
            <span class="pmccite-icon fa fa-quote-left" title="被引用"></span>
            <span class="pmccite-num" title="被引用">{{paper.pmcCites.length}}</span>
          </router-link>
        </h1>
        <div v-show="switchStatus" class="p-title-ch">{{paper.tCh}}</div>
        <div class="p-author-wrap">
          <div class="p-authors">
            <h2 class="title">Authors
              <span @click.stop="toggleExpand" class="aff-icon fa" :class="[expandAffiliations?'fa-minus-square-o': 'fa-plus-square']"></span>
              <span v-show="!expandAffiliations">
                <span class="authoridx" v-if="paper.authorIdx && paper.authorIdx.length">
                  <span class="name" v-for="(item, index) in paper.authorIdx" :key="index">
                    <router-link class="full-name" target="_blank" :to="{name: 'paperSearch', query: {term: item.name + '[Author]'}}">
                      {{item.name}}
                    </router-link>
                    <span v-if="index<paper.authorIdx.length-1">,</span>
                    <!-- <img v-if="item.idx===0" :class="{last:paper.authorIdx.length===1}" src="../../assets/imgs/number1.png"/> -->
                    <!-- <img v-if="item.idx===1" :class="{last:paper.authorIdx.length===2}" src="../../assets/imgs/number2.png"/> -->
                    <img v-if="item.idx===-2" :class="{last:paper.authorIdx.length===3}" src="../../assets/imgs/number-2.png"/>
                    <img class="last" v-if="item.idx===-1" src="../../assets/imgs/number-1.png"/>
                  </span>
                </span>
              </span>
            </h2>
            <!-- <div v-if="paper.authorList" v-html="paper.authorList"></div> -->
            <div v-show="expandAffiliations" v-if="paper.authorafs && paper.authorafs.authorAfsList" class="authors-list">
              <span class="authors-list-item" v-for="(item, index) in paper.authorafs.authorAfsList" :key="index">
                <!-- <a class="full-name">{{item.name}}</a> -->
                <router-link class="full-name" target="_blank" :to="{name: 'paperSearch', query: {term: item.name + '[Author]'}}">
                  {{item.name}}
                </router-link>
                <sup class="affiliation-links">
                  <span v-for="idx in item.afsIdxList" :key="idx">
                    <span class="author-sup-separator">&nbsp;</span>
                    <a class="affiliation-link" href="#affiliation-1" ref="linksrc=author_aff">{{idx}}</a>
                  </span>
                </sup>
                <span class="comma" v-if="index<paper.authorafs.authorAfsList.length-1">,&nbsp;</span>
              </span>
            </div>
            <h2 v-show="expandAffiliations" class="title" style="margin-top: 0.25rem;">Affiliations</h2>
            <!-- <div v-show="expandAffiliations" v-html="paper.affiliations"></div> -->
            <div v-show="expandAffiliations" v-if="paper.authorafs && paper.authorafs.affilationList">
              <ul class="item-list">
                <li v-for="(item, index) in paper.authorafs.affilationList" :key="index"><sup class="key">{{index + 1}}</sup>{{item}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="p-abstract">
        <h2 class="title">Abstract</h2>
        <div v-html="paper.abstractHTML" class="abstract-en" :class="{translate: switchStatus}"></div>
        <div v-show="switchStatus" class="abstract-ch" :class="{translate: switchStatus}" v-if="paper.abCh || paper.absCh">{{paper.abCh || new Array(paper.absCh).join('。')}}</div>
      </div>
      <div class="p-keywords" v-if="paper.a && paper.a.kw">
        <span class="keyword">Keywords:</span><span>{{paper.a.kw}}</span>
      </div>
      <div class="p-identifiers">
        <span class="pmid"><a target="_blank" :href="paper.pubmedLink">{{`PMID: `+paper.id}}</a></span>
        <span class="pmcid">
          <a v-if="paper.ids && paper.ids.pmcid">{{`PMCID: `+paper.ids.pmcid}}</a>
        </span>
        <span class="doi" v-if="paper.ids && paper.ids.doi"><a target="_blank" :href="paper.doiLink">{{`DOI: `+paper.ids.doi}}</a></span>
      </div>
      <div class="paper-cite-panel">
        <span class="cite-icon fa fa-quote-left"></span>
        <span class="text" @click.stop="getCiteFormat">引用格式</span>
        <span class="cite-icon fa fa-share-square-o"></span>
        <span class="text" @click.stop="exportEndNote">EndNote格式</span>
        <span class="cite-icon fa fa-share-square-o"></span>
        <span class="text" @click.stop="exportNoteExpress">NoteExpress格式</span>
        <label class="bui-switch-label bui-switch-animbg">
          <input type="checkbox" v-model="switchStatus" name="s"/><span class="bui-switch"><span class="switch-tip">{{switchLabel}}</span></span>
        </label>
        <span class="text">Google翻译</span>
      </div>
    </div>
    <!-- <div>split</div>---------------------------------------------------------------------------------------------- -->
    <div class="boding page-box" v-if="!showLoading">
      <div class="p-figs" v-if="paperOther.figs && paperOther.figs.length">
        <h2 class="title">Figures</h2>
        <div class="fig-item" v-for="(item, index) in paperOther.figs" :key="index" @click.stop="handleShowFig(item, index)">
          <a><img :src="item.thumbnail" alt=""><img style="display:none;" :src="item.imgUrl" alt=""></a>
          <div class="fig-caption" v-html="item.title"></div>
        </div>
      </div>
      <div class="p-similar" v-if="paperOther.commentIns && paperOther.commentIns.length">
        <h2 class="title">Comment in</h2>
        <ul class="article">
          <li v-for="(item, index) in paperOther.commentIns" :key="index">
            <div class="title">
              <router-link target="_blank" :to="{name: 'paper', params: {id: item.pmid}}" v-html="item.title"></router-link>
            </div>
            <div class="authors"><span>{{item.authors}}</span></div>
            <div class="citation">
              <span>{{item.citation}}</span>
            </div>
            <div class="ft">
              <span class="pmid">PMID:{{item.pmid}}</span>
              <span class="pmc">{{item.freeResources}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="p-similar" v-if="paperOther.similars && paperOther.similars.length">
        <h2 class="title">Similar articles</h2>
        <ul class="article">
          <li v-for="(item, index) in paperOther.similars" :key="index">
            <div class="title">
              <router-link target="_blank" :to="{name: 'paper', params: {id: item.pmid}}" v-html="item.title"></router-link>
            </div>
            <div class="authors"><span>{{item.authors}}</span></div>
            <div class="citation">
              <span>{{item.citation}}</span>
            </div>
            <div class="ft">
              <span class="pmid">PMID:{{item.pmid}}</span>
              <span class="pmc">{{item.freeResources}}</span>
              <span class="pubtype">{{item.pubType}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="p-similar" v-if="paperOther.citedbys && paperOther.citedbys.length">
        <h2 class="title">Cited by&nbsp;&nbsp;{{paperOther.citedbyNum}}</h2>
        <ul class="article">
          <li v-for="(item, index) in paperOther.citedbys" :key="index">
            <div class="title">
              <router-link target="_blank" :to="{name: 'paper', params: {id: item.pmid}}" v-html="item.title"></router-link>
            </div>
            <div class="authors"><span>{{item.authors}}</span></div>
            <div class="citation">
              <span>{{item.citation}}</span>
            </div>
            <div class="ft">
              <span class="pmid">PMID:{{item.pmid}}</span>
              <span class="pmc">{{item.freeResources}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="p-cited"></div>
      <div class="p-references" v-if="paperOther.refs && paperOther.refs.length">
        <h2 class="title">References</h2>
        <ol class="p-references-list">
          <li v-for="(item, index) in paperOther.refs" :key="index">{{item}}</li>
        </ol>
      </div>
      <div class="p-mesh" v-if="paperOther.meshs && paperOther.meshs.length">
        <h2 class="title">MeSH terms</h2>
        <div class="mesh-list" v-html="paperOther.meshs"></div>
      </div>
    </div>
    <fig-win :show="showFig" @on-hide="showFig=false"
      :fig="currentFig"
      :index="currentFigIndex"
      :figSum="paperOther.figs && paperOther.figs.length"
      @on-prev="onPreFig"
      @on-next="onNextFig"></fig-win>
    <citation-win :show="showCite" :left="winleft" :top="wintop" @on-hide="showCite=false" :citation="citation"></citation-win>
    <login-tip-win :show="showLoginTipWin" @on-hide="showLoginTipWin=false"></login-tip-win>
    <login-tip-win2 :show="showLoginTipWin2" @on-hide="showLoginTipWin2=false"></login-tip-win2>
    <tooltip-win :show="showSaveResultWin" @on-hide="showSaveResultWin=false" :autoClose="autoClose" :tipType="tipType" :duration="duration" :text="saveResultText"></tooltip-win>
    <confirm-win :show="showConfirmWin" @on-hide="showConfirmWin=false" :title="confirmTitle" :text="confirmText" okText="重新提交" @on-ok="onConfirmOk"></confirm-win>
    <email-win :show="showEmailWin" @on-hide="showEmailWin=false" @on-ok="onEmail"></email-win>
    <div class="if-chart" v-show="showIfList" :style="chartPos">
      <div class="chart-box">
        <y-line :width="500" :height="200" :data="ifList" :chartTitle="chartTitle" :options="optionsIfList"></y-line>
      </div>
    </div>
    <div class="j-full-title" v-show="showJFullTitle" :style="chartPos">
      <div class="j-full-title-box">{{chartTitle}}</div>
    </div>
  </div>
</template>
<script>
import LoadingBar from '../common/loading/loading-bar'
import SearchForm from '../paper-search/search-form'
import FigWin from './fig-win'
import CitationWin from '../paper-search/citation-inner-win'
import EmailWin from '../paper-search/email-win'
import LoginTipWin from '../login-tip-win/login-tip-win'
import LoginTipWin2 from '../login-tip-win/login-tip-win2'
import TooltipWin from '../login-tip-win/tooltip-win'
import ConfirmWin from '../login-tip-win/confirm-win'
import loadPaperMixin from './mixins/loadPaper'
import paperCollect from './mixins/paperCollect'
import ChartMixin from '../paper-search/mixins/chart'
import YLine from '../common/charts/line'
import transMethod from '../paper-search/mixins/translate'
import Login from '../../utils/login'
import { authenticate, catchExpired } from '../../utils/auth'
import { getStorageItem, updateStorageItem } from '../../utils/cache'
export default {
  components: { LoadingBar, SearchForm, FigWin, CitationWin, LoginTipWin, LoginTipWin2, TooltipWin, ConfirmWin, EmailWin, YLine },
  mixins: [transMethod, loadPaperMixin, paperCollect, ChartMixin, Login],
  data () {
    let _te = getStorageItem('_te')
    let te = true
    let la = '关闭'
    if (_te && _te !== '0') {
      te = false
      la = '打开'
    }
    return {
      term: '',
      pmid: '',
      paper: {},
      paperOther: {},
      showLoading: true,

      citation: '',
      showCite: false,
      winleft: 0,
      wintop: 0,

      showLoginTipWin: false,
      showLoginTipWin2: false,

      expandAffiliations: false,
      switchLabel: la,
      switchStatus: te,

      showFig: false,
      currentFig: {},
      currentFigIndex: 0,

      showEmailWin: false,
      showSaveResultWin: false,
      duration: 1500,
      tipType: 'success',
      saveResultText: '',
      autoClose: true,

      showConfirmWin: false,
      confirmTitle: '您的邮箱地址尚未验证通过！',
      confirmText: ''
    }
  },
  computed: {
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    },
    scihubDomain () {
      return getStorageItem('domain')
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.params && to.params.id) {
      next(vm => {
        vm.pmid = Number(to.params.id)
        vm.loadPaperOne(vm.pmid, () => {
          vm.loadPaperRest(() => {
            vm.translatePaper()
            vm.loadAltmetric()
            // vm.loadPaperPmcCites(pmcCites => {
            //   if (pmcCites[vm.paper.id]) {
            //     vm.paper.pmcCites = pmcCites[vm.paper.id]
            //     vm.paper.pmcCite = pmcCites[vm.paper.id].length
            //   }
            // })
          })
          // get call paper if list
          let pmidIssnList = []
          pmidIssnList.push({pmid: vm.paper.id, issn: vm.paper.j ? vm.paper.j.issn || '' : ''})
          vm.getIfList(pmidIssnList, iflistMap => {
            if (iflistMap[vm.paper.id] && iflistMap[vm.paper.id].length) {
              vm.paper.ifList = iflistMap[vm.paper.id] || []
            }
          })
          vm.getPdfUrl()
        })
        vm.loadPaper()
        if (to.query && to.query.email) {
          vm.sendCountMsg(to.params.id, to.query.email)
        }
      })
    } else {
      next()
    }
  },
  watch: {
    switchStatus (val) {
      if (val) {
        this.switchLabel = '关闭'
        updateStorageItem('_te', 0)
      } else {
        this.switchLabel = '打开'
        updateStorageItem('_te', 1)
      }
    }
  },
  methods: {
    toggleExpand () {
      this.expandAffiliations = !this.expandAffiliations
    },
    onTerm (term) {
      this.term = term
    },
    onSearch (term) {
      this.term = term
      this.$router.push({name: 'paperSearch', query: {term: this.term}})
    },
    loadPaper () {
      this.$http.post(`${this.httpRoot}/paper/detail`, {pmid: this.pmid}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paperOther = res.paper || {}
          this.showLoading = false
          setTimeout(() => {
            this.refactMesh()
          }, 500)
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    translatePaper () {
      document.title = this.paper.a.t
      if (!this.paper.tCh) {
        this.translate2zh(this.paper.a.t, ch => {
          this.paper.tCh = ch
          this.updateTranslate(ch, 'title')
        })
      }
      if (!this.paper.abCh && !this.paper.absCh) {
        this.translate2zh(this.paper.abstract, ch => {
          this.paper.abCh = ch
          this.updateTranslate(ch, 'abstract')
        })
      }
    },
    // translate (en, type, callback) {
    //   this.$http.post(`${this.httpRoot}/en2ch/translate`, {en, pmid: this.pmid, type}, authenticate())
    //   .then(res => res.json())
    //   .then(res => {
    //     if (res.status === 'success' && res.ch) {
    //       if (typeof callback === 'function') {
    //         callback(res.ch)
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
    // },
    updateTranslate (text, type) {
      this.$http.post(`${this.httpRoot}/paper/translation/update`, {text, pmid: this.pmid, type}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success' && res.ch) {
          //
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    getCiteFormat (e) {
      this.winleft = e.clientX
      this.wintop = e.clientY

      this.$http.get(`${this.httpRoot}/paper/citation`, authenticate({params: {pmid: this.paper.id}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.citation = res.citation || ''
          this.showCite = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getPdfUrl () {
      if (this.paper.fulltextLink) return
      this.$http.get(`${this.httpRoot}/paper/fulltextlink`, authenticate({params: {pmid: this.paper.id, doi: this.paper.ids.doi}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paper.fulltextLink = res.url || ''
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onLinkClick (e) {
      if (!this.loggedIn) {
        e.preventDefault()
        updateStorageItem('route', {name: 'paper', params: {'id': this.paper.id}})
        this.showLoginTipWin2 = true
        return false
      } else {
        return true
      }
    },
    onFulltextHelp (e) {
      if (!this.onLinkClick(e)) return
      this.$http.post(`${this.httpRoot}/paper/fulltext/add`, {
        title: this.paper.a.t,
        pmid: this.paper.id,
        doi: this.paper.ids.doi
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.fulltextHelpSuccess()
        } else if (res.emailValid === 'null') {
          this.showEmailWin = true
        } else if (res.emailValid === 'false') {
          this.emailFailed(res.email)
        } else {
          this.fulltextHelpFailed(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    exportEndNote () {
      this.$http.post(`${this.httpRoot}/paper/endnote/export`, {
        pmids: [this.paper.id]
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'endnote.' + this.paper.id + '.nbib'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    exportNoteExpress () {
      this.$http.post(`${this.httpRoot}/paper/noteexpress/export`, {
        pmids: [this.paper.id]
      }, authenticate({responseType: 'blob'}))
      .then(res => res.blob())
      .then(blob => {
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'noteexpress.' + this.paper.id + '.net'
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleCollectToggle () {
      if (!this.loggedIn) {
        updateStorageItem('route', {name: 'paper', params: {'id': this.paper.id}})
        this.showLoginTipWin = true
        return
      }
      if (this.paper.status === 'collect') {
        this.uncollect()
      } else {
        this.collect()
      }
    },
    handleReadToggle () {
      if (!this.loggedIn) {
        updateStorageItem('route', {name: 'paper', params: {'id': this.paper.id}})
        this.showLoginTipWin = true
        return
      }
      if (this.paper.read) {
        this.unread()
      } else {
        this.read()
      }
    },
    collect () {
      this.$http.post(`${this.httpRoot}/paper/collect`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paper.status = 'collect'
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    uncollect () {
      this.$http.post(`${this.httpRoot}/paper/uncollect`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paper.status = ''
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    read () {
      this.$http.post(`${this.httpRoot}/paper/read`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paper.read = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    unread () {
      this.$http.post(`${this.httpRoot}/paper/unread`, {pmids: [this.paper.id]}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.paper.read = false
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleShowFig (item, index) {
      this.currentFig = item
      this.currentFigIndex = index
      this.showFig = true
    },
    onPreFig (index) {
      if (index >= 0) {
        this.currentFig = this.paperOther.figs[index]
        this.currentFigIndex = index
      }
    },
    onNextFig (index) {
      if (index < this.paperOther.figs.length) {
        this.currentFig = this.paperOther.figs[index]
        this.currentFigIndex = index
      }
    },
    handleJournalMouseEnter (e) {
      let rect = {left: e.clientX + 100, top: e.clientY}
      if (e.currentTarget) {
        let bdrect = e.currentTarget.getBoundingClientRect()
        rect = {left: bdrect.left + 100, top: bdrect.top}
      }
      let journalFullTitle = ''
      if (this.paper.j && this.paper.j.t) {
        journalFullTitle = this.paper.j.t
        if (journalFullTitle.indexOf(':') > 0) {
          let ary = journalFullTitle.split(':')
          if (ary.length > 0) {
            journalFullTitle = ary[0].trim()
          }
        }
      }
      this.onJournalMouseEnter(this.paper.ifList, rect, journalFullTitle)
    },
    handleJournalMouseLeave () {
      this.onJournalMouseLeave()
    },
    refactMesh () {
      let mesh = this.$el.querySelectorAll('.keyword-link')
      for (let i = 0; i < mesh.length; i++) {
        let item = mesh[i]
        item.style.display = 'none'
        let parentEle = item.parentElement
        let linkul = parentEle.querySelector('.keyword-actions-links')
        let linka = null
        if (linkul) {
          linka = linkul.firstChild.firstChild
          let href = linka.getAttribute('href')
          //
          let a = document.createElement('a')
          a.className = 'mesh-link'
          a.innerHTML = item.innerHTML
          a.setAttribute('target', '_blank')
          a.setAttribute('href', '/pubmed' + href.slice(1))
          parentEle.insertBefore(a, item)
        }
      }
    },
    fulltextHelpSuccess () {
      this.autoClose = false
      this.tipType = 'success'
      this.duration = 1500
      this.saveResultText = '求助文献已进入文献互助，等待他人应助'
      this.showSaveResultWin = true
    },
    fulltextHelpFailed (text) {
      this.autoClose = false
      this.tipType = 'warning'
      this.duration = 2000
      this.saveResultText = text || '求助文献失败'
      this.showSaveResultWin = true
    },
    emailFailed (email) {
      this.confirmText = '<div class="tip01">' +
                            '<div>原因可能如下：1.填写的邮箱地址' + email + '不正确；2.未在您的邮箱中点击验证邮件；3.您的邮箱未收到验证邮件。</div>' +
                            '<div class="tipfoot01">您也可以发送您的微信昵称至管理员邮箱(service100@iyxy.cn)手动完成邮箱验证。</div>' +
                          '</div>'
      this.showConfirmWin = true
    },
    onConfirmOk () {
      this.showEmailWin = true
    },
    onEmail (email) {
      this.$http.post(`${this.httpRoot}/auth/email/update`, {email: email}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.autoClose = false
          this.tipType = 'success'
          this.duration = 1500
          this.saveResultText = '邮箱已设置，确认邮件已发送到您的邮箱，请查看邮件进行确认'
          this.showSaveResultWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    sendCountMsg (pmid, email) {
      this.$http.post(`${this.httpRoot}/auth/email/click`, {pmid, email, type: 'push'}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  },
  errorCaptured (err, vm, info) {
    console.log('paper', err, vm, info)
  }
}
</script>
<style lang="less">
.heading {
  border-bottom: 1px solid #ccc;
  .search-box {
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
  }
}
.boding {
  position: relative;
}
.save-results .fa-heart {
  color: #ff6112;
}
.read-results {
  .read {
    padding: 0 8px;
    height: 14px;
    text-align: center;
    background: url(../../assets/imgs/read.png) no-repeat center/contain;
  }
  .unread {
    padding: 0 8px;
    height: 14px;
    text-align: center;
    background: url(../../assets/imgs/readed.png) no-repeat center/contain;
  }
}
.aff-icon {
  color: #008cde;
}
.yxy-score-p {
  background: #ecd250;
  margin-right: 2px;
  span {
    display: inline-block;
    margin: 2px 7px;
    font-weight: 600;
  }
}
.full-text-links-list {
  display: flex;
  justify-content: center;
  .link-item {
    margin: 0 0.3rem;
    display: flex;
    &.pmc {
      display: block;
      width: 9rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      background: url(../../assets/imgs/pmc-full-text.png) no-repeat;
    }
  }
  .text {
    display: none;
  }
}
.pmc-text-link {
  display: block;
  width: 9rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  background: url(../../assets/imgs/pmc-full-text.png) no-repeat;
}
.journal-full-link {
  padding: 0 0.2rem;
  img {
    display: block;
    margin-right: 0.4rem;
    height: 1.4rem;
  }
}
.pmc-full-link {
  display: block;
  width: 6rem;
  height: 1.4rem;
  line-height: 1.4rem;
  padding: 0 0.2rem;
  text-align: center;
  background: url(../../assets/imgs/pmc-full-text.png) no-repeat center/contain;
}
.sci-hub-link {
  display: block;
  width: 6rem;
  height: 1.4rem;
  line-height: 1.4rem;
  padding: 0 0.2rem;
  text-align: center;
  background: url(../../assets/imgs/sci-hub.png) no-repeat center/contain;
}
.pdf-link {
  display: block;
  width: 2rem;
  height: 1.4rem;
  text-align: center;
  background: url(../../assets/imgs/pdf.png) no-repeat center/contain;
}
.web-link {
  display: block;
  width: 2rem;
  height: 1.4rem;
  text-align: center;
  background: url(../../assets/imgs/link.png) no-repeat center/contain;
}
.fulltext-help {
  width: 5rem;
  height: 1rem;
  text-align: center;
  background: url(../../assets/imgs/help.png) no-repeat center/contain;
}
.header-tools-wrap {
  height: 3.5rem;
  line-height: 3.5rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-tools {
    position: relative;
    display: flex;
    align-items: center;
    button {
      cursor: pointer;
      color: #212121;
      background-color: transparent;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      text-decoration: none;
      border: 1px solid #aeb0b5;
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;
      padding: 0.3rem 0.8rem;
      height: 2.2rem;
      margin-right: 1rem;
    }
    .save-results-panel {
      position: absolute;
      z-index: 11;
      top: 0;
      left: 0;
      transform: translateY(45px);
      background: #fff;
      box-shadow: 0 0 5px rgba(0,0,0,0.45);
      &>li {
        list-style: none;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
  }
}
.p-attr {
  margin-top: 2rem;
  display: flex;
  height: 1.5rem;
  line-height: 1.5rem;
  align-items: center;
  .type, .journal {
    color: #0071bc;
  }
  .type {
    background-color: #dce4ef;
    color: #205493;
    padding: 0.2rem 1rem;
    margin-right: 1rem;
  }
  .journal-title {
    color: #0071bc;
  }
  .date {
    padding-right: 4px;
    color: #d82424;
  }
  .pii, .doi, .pubst {
    padding-left: 4px;
  }
  .journal, .if, .quality, .other {
    margin: 0;
    padding: 0 5px;
    border: 2px solid #fff;
    flex: 0 0 auto;
  }
  .if, .quality {
    background-color: #fff;
  }
  .quality {
    margin-right: 2px;
  }
  .if, .quality {
    &.red {
      border-color: #ff2929;
    }
    &.yellow {
      border-color:#ff8a12;
    }
    &.blue {
      border-color:#008cde;
    }
    &.green {
      border-color:#00b561;
    }
    &.grey {
      border-color: grey;
    }
  }
  .if, .quality {
    &.red {
      background-color: #ff2929;
      color: #fff;
    }
    &.yellow {
      background-color:#ff8a12;
      color: #fff;
    }
    &.blue {
      background-color:#008cde;
      color: #fff;
    }
    &.green {
      background-color:#00b561;
      color: #fff;
    }
    &.grey {
      background-color: grey;
      color: #fff;
    }
  }
}
.p-title {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Merriweather","Georgia","Cambria","Times New Roman","Times",serif;
  color: #212121;
  line-height: 1.2;
  margin: 0.875rem 0;
  .altmetric-icon {
    margin-left: 0.8rem;
    margin-right: 0.25rem;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-image: url(../../assets/imgs/altmetric-logo.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .altmetric-num {
    font-size: 1rem;
    color: #ff8a12;
  }
  .pmccite-icon {
    margin-left: 1rem;
    color: #2c3e50;
  }
  .pmccite-num {
    margin-left: 0.2rem;
    color: #2c3e50;
  }
}
.p-author-wrap {
  margin-top: 1rem;
  position: relative;
  top: -0.3rem;
  padding-left: 1.2rem;
  border-left: 0.3rem solid #d6d7d9;
  color: #212121;
}
.p-authors {
  .title {
    font-size: 1.25rem;
    font-weight: 600;
    font-family: "Merriweather","Georgia","Cambria","Times New Roman","Times",serif;
    margin: 1rem 0 0 0;
  }
  .item-list {
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
    display: block;
    list-style: none;
    &>li {
      position: relative;
      padding-left: 1.4rem;
      &>.key {
        display: block;
        width: 1.2rem;
        padding-right: 0.4rem;
        line-height: 1.2rem;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        color: #5b616b;
      }
    }
  }
  a {
    color: #0071bc;
    &:visited, &:active {
      color: #0071bc;
    }
    &.hover {
      color: #205493;
    }
  }
}
.authoridx {
  padding-left: 10px;
  .name {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    img {
      position: absolute;
      top: -3px;
      right: -0px;
      width: 12px;
      height: 12px;
      &.last {
        right: -10px;
      }
    }
  }
}
.affiliation-links {
  font-size: 75%;
  top: -0.5em;
}
.affiliation-link {
  font-size: inherit;
  display: inline-block;
  line-height: 1;
  padding: 0.1rem 0.3rem;
  border-radius: 2px;
  background-color: #f1f1f1;
  color: #323a45;
  transition: color 0.3s;
}
.p-identifiers {
  margin: 1rem 0 2rem 0;
  .pmid, .pmcid, .doi {
    margin-right: 1rem;
  }
}
.p-abstract {
  font-size: 1rem;
  &> .title {
    font-size: 1.375rem;
    font-weight: 600;
    font-family: "Merriweather","Georgia","Cambria","Times New Roman","Times",serif;
    color: #212121;
    margin: 0.5rem 0 0.25rem 0;
  }
  &> .abstract-en {
    &.translate {
      width: 60%;
      float: left;
      margin-right: 2%;
    }
  }
  &> .abstract-ch {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    &.translate {
      width: 30%;
      float: left;
      margin-left: 2%;
    }
  }
  &:after {
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}
.p-keywords {
  margin-top: 1rem;
  .keyword {
    font-weight: bold;
    font-size: 1rem;
    padding-right: 0.2rem;
    color: #212121;
  }
}
.p-figs {
  &:after {
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
  .fig-item {
    float: left;
    width: 15rem;
    height: 17rem;
    border: 1px solid #ccc;
    background: #eee;
    margin-right: 2rem;
    margin-bottom: 2rem;
    &> a {
      height: 12rem;
      display: block;
      text-align: center;
      position: relative;
      background: #fff;
      &> img {
        max-width: calc(100% - 1rem);
        max-height: calc(100% - 1rem);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    &> .fig-caption {
      margin: 1rem;
      height: 3rem;
      font-size: 0.875rem;
      cursor: default;
      overflow: hidden;
      .figure-label, .figure-caption-contents {
        display: inline;
      }
    }
  }
}
.p-similar {
  &> .article {
    list-style: none;
    &> li {
      margin-bottom: 1.5rem;
      &> .title {
        font-size: 1rem;
        &> a {
          color: #0071bc;
          &:active, &:hover {
           color: #0a6099;
          }
          &:visited {
            color: #4c2c92;
          }
        }
      }
      &> .authors {
        line-height: 20px;
        font-size: 85%;
      }
      &> .citation {
        line-height: 20px;
        color: #2e8540;
        font-size: 85%;
      }
      &> .ft {
        line-height: 20px;
        font-size: 90%;
        .pmid {
          color: #2e8540;
        }
        .pmc {
          color: #C05600;
          margin-left: 28px;
        }
        .pubtype {
          color: #2e8540;
          margin-left: 28px;
        }
      }
    }
  }
}
.p-references-list {
  font-size: 0.875rem;
  padding-left: 1.5rem;
  &> li {
    padding-left: 0.5rem;
  }
}
.p-figs, .p-references, .p-similar, .p-mesh {
  margin-top: 2rem;
  &> .title {
    font-size: 1.375rem;
    font-weight: 500;
    font-family: "Merriweather","Georgia","Cambria","Times New Roman","Times",serif;
    color: #212121;
    margin: 1rem 0 0.5rem 0;
  }
}
.paper-cite-panel {
  position: absolute;
  top: -1.8rem;
  right: 1rem;
  cursor: pointer;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  &>span {
    &:hover {
      color: #216892;
    }
  }
  .text {
    margin-right: 0.5rem;
  }
}
/*switch*/
label {
  &.bui-switch-label {
    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      &:checked {
        border-color: #64bd63;
        box-shadow: #64bd63 0 0 0 16px inset;
        background-color: #64bd63;
        &+ .bui-switch {
          border-color: #64bd63;
          box-shadow: #64bd63 0 0 0 16px inset;
          background-color: #64bd63;
          &:before {
            left: 29px;
          }
        }
      }
    }
    &.bui-switch-animbg {
      transition: background-color ease 0.4s;
      input {
        &:checked {
          &+ .bui-switch {
            box-shadow: #dfdfdf 0 0 0 0 inset;
            background-color: #64bd63;
            transition: border-color 0.4s, background-color ease 0.4s;
            &:before {
              transition: left 0.3s;
            }
            .switch-tip {
              margin-left: 0;
              color: #ccc;
              padding: 0 2px;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
      .bui-switch {
        &:before {
          transition: left 0.3s;
        }
        .switch-tip {
          margin-left: 17px;
          padding: 0 2px;
          &:hover {
            color: #00c732;
          }
        }
      }
    }
    .bui-switch {
      width: 46px;
      height: 18px;
      position: relative;
      border: 1px solid #dfdfdf;
      background-color: #fdfdfd;
      box-shadow: #dfdfdf 0 0 0 0 inset;
      border-radius: 10px;
      background-clip: content-box;
      display: inline-block;
      -webkit-appearance: none;
      user-select: none;
      outline: none;
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
.mesh-list {
  &> .keywords-list {
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
    margin-bottom: -1.5rem;
    &> li {
      display: inline-block;
      margin-bottom: 1.5rem;
      margin-right: 1rem;
      .mesh-link {
        display: inline-block;
        line-height: 1.5rem;
        padding: .25rem 1rem;
        border-radius: 1rem;
        color: #205493;
        background-color: #dce4ef;
        transition: color 0.2s;
        border: 0;
        outline: 0;
        &:active, &:hover {
          color:#173c69;
        }
        &:visited {
          color: #4c2c92;
        }
      }
    }
    .asterisk {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      overflow: hidden;
      font-size: 200%;
      margin: 0 -0.5rem 0.1rem 0.5rem;
    }
  }
}
@media screen and (max-width: 700px) {
  .header-tools-wrap>.header-tools {
    &>.save-results {
      display: none;
    }
    &>.save-results-panel {
      display: none;
    }
    &>.read-results {
      display: none;
    }
    &>.label {
      display: none;
    }
  }
  .p-header>.p-attr {
    &>.date, &>.vol, &>.doi {
      display: none;
    }
  }
}
</style>
